import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';
import { selectorViewSelectorBlogItems } from '../../../../../store/adminBlogSlice';
import { formatDateHHMMDDMMYYYY } from '../../../../../utils/formatDateHHMMDDMMYYYY';
import classes from './AdminEducationAnnounceTable.module.css';
import AdminEducationAnnounceTableActions from './AdminEducationAnnounceTableActions/AdminEducationAnnounceTableActions';
import AdminEducationAnnounceTableBadge from './AdminEducationAnnounceTableBadge/AdminEducationAnnounceTableBadge';
import { ADMIN_EDUCATION_ANNOUNCE_TABLE_COLUMNS, AdminBlogTableDataType } from './adminEducationAnnounceTableColumns';
import AdminEducationAnnounceTableCoverImg from './AdminEducationAnnounceTableCoverImg/AdminEducationAnnounceTableCoverImg';
import AdminEducationAnnounceTableItemType from './AdminEducationAnnounceTableItemType/AdminEducationAnnounceTableItemType';
import AdminEducationAnnounceTableTags from './AdminEducationAnnounceTableTags/AdminEducationAnnounceTableTags';
import { selectorViewSelectorEducationAnnounceItems } from '../../../../../store/adminEducationSlice';

interface IAdminBlogTableProps {}

const AdminEducationAnnounceTable: React.FC<IAdminBlogTableProps> = () => {
  const announceItems = useAppSelector(selectorViewSelectorEducationAnnounceItems)?.data;

  const tableData: AdminBlogTableDataType[] =
    announceItems?.map((item: any) => {
      return {
        statusBadge: <AdminEducationAnnounceTableBadge status={item.status} />,
        coverImg: <AdminEducationAnnounceTableCoverImg imgUrl={item.cover?.path} />,
        name: item.name ?? '',
        type: <AdminEducationAnnounceTableItemType itemType={item.type} />,
        dataTime: (
          <div className={classes.dataTime}>
            {item._alter_ts ? formatDateHHMMDDMMYYYY(new Date(item._alter_ts)) : ''}
          </div>
        ),
        tags: <AdminEducationAnnounceTableTags tags={item.tags} />,
        actions: <AdminEducationAnnounceTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_EDUCATION_ANNOUNCE_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminEducationAnnounceTable;
