import { SortingItemType } from '../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SortingSelector';
import { getAdminBlogItemsThunk, selectorViewSelectorBlogItems } from '../store/adminBlogSlice';
import { getQueryPagination } from '../utils/adminBlog/getQueryPagination';
import { getQueryPaginationFromFirstPage } from '../utils/adminBlog/getQueryPaginationFromFirstPage';
import { getQuerySearchValue } from '../utils/adminBlog/getQuerySearchValue';
import { getQuerySortValue } from '../utils/adminBlog/getQuerySortValue';
import { combineQuery } from '../utils/combineQuery';
import { useAppDispatch, useAppSelector } from './hooks';
import { getQueryInArchive } from '../utils/adminBlog/getQueryInArchive';
import {
  getAdminEducationAnnounceItemsThunk,
  selectorAdminEducationFilter,
  selectorEducationViewSelectorActiveSortingId,
  selectorEducationViewSelectorInArchive,
  selectorEducationViewSelectorSearchValue,
} from '../store/adminEducationSlice';

interface useGetBlogItemsType {
  searchValue?: string | null | undefined;
  queryPagination?: string | undefined;
  sortingItem?: SortingItemType;
  inArchive?: boolean;
  filterValue?: string;
}

export const useGetEducationAnnounceItems = () => {
  const dispatch = useAppDispatch();
  const searchValueStore = useAppSelector(selectorEducationViewSelectorSearchValue);
  const inArchiveValueStore = useAppSelector(selectorEducationViewSelectorInArchive);
  const paginationValueStore = useAppSelector(selectorViewSelectorBlogItems)?.meta;
  const activeSortingIdStore = useAppSelector(selectorEducationViewSelectorActiveSortingId);
  const filterValueStore = useAppSelector(selectorAdminEducationFilter);

  return ({ searchValue, queryPagination, sortingItem, inArchive, filterValue }: useGetBlogItemsType) => {
    const querySearchValue =
      searchValue === null ? '' : getQuerySearchValue(searchValue ? searchValue : searchValueStore);

    const queryInArchiveValue = getQueryInArchive(inArchive ? inArchive : inArchiveValueStore);
    // console.log(queryInArchiveValue);
    const queryPaginationValue = queryPagination ? queryPagination : getQueryPagination(paginationValueStore);
    const querySortingValue = sortingItem ? getQuerySortValue(sortingItem.id) : getQuerySortValue(activeSortingIdStore);

    const filter = filterValue !== undefined ? filterValue : filterValueStore;
    //console.log(filter);
    const requestQuery = combineQuery(
      querySearchValue,
      querySortingValue,
      queryPaginationValue ? queryPaginationValue : getQueryPaginationFromFirstPage(paginationValueStore),
      queryInArchiveValue,
      filter
    );

    dispatch(getAdminEducationAnnounceItemsThunk(requestQuery));
  };
};
