import {
  getDocumentsThunk,
  selectorMethodologyDocumentsMeta,
  selectorMethodologySearch,
  selectorMethodologySelectedProcess,
  selectorSelectedDocumentStatuses,
  selectorSelectedDocumentTags,
  selectorSelectedDocumentTypes,
} from '../store/methodologySlice';
import { ComboboxItemType, MethodologyProcessType } from '../types/types';
import { combineQuery } from '../utils/combineQuery';
import { makePaginationQuery } from '../utils/methodologyUtils/makePaginationQuery';
import { makeSelectedDocumentStatusesQuery } from '../utils/methodologyUtils/makeSelectedDocumentStatusesQuery';
import { makeSelectedDocumentTagsQuery } from '../utils/methodologyUtils/makeSelectedDocumentTagsQuery';
import { makeSelectedDocumentTypesQuery } from '../utils/methodologyUtils/makeSelectedDocumentTypesQuery';
import { makeSelectedProcessQuery } from '../utils/methodologyUtils/makeSelectedProcessQuery';
import { useAppDispatch, useAppSelector } from './hooks';
import { makeSelectedSearchQuery } from '../utils/methodologyUtils/makeSelectedSearchQuery';

interface IUseGetMethodologyDocuments {
  selectedProcess?: MethodologyProcessType | null;
  paginationQuery?: string;
  selectedDocumentStatuses?: ComboboxItemType[];
  selectedDocumentTypes?: ComboboxItemType[];
  selectedDocumentTags?: ComboboxItemType[];
  selectedSearch?: string;
}

export const useGetMethodologyDocuments = () => {
  const dispatch = useAppDispatch();
  const selectedProcessStore = useAppSelector(selectorMethodologySelectedProcess);
  const documentsMeta = useAppSelector(selectorMethodologyDocumentsMeta);
  const selectedDocumentStatusesStore = useAppSelector(selectorSelectedDocumentStatuses);
  const selectedDocumentTypesStore = useAppSelector(selectorSelectedDocumentTypes);
  const selectedDocumentTagsStore = useAppSelector(selectorSelectedDocumentTags);
  const selectedSearchStore = useAppSelector(selectorMethodologySearch);

  return ({
    selectedProcess,
    paginationQuery,
    selectedDocumentStatuses,
    selectedDocumentTypes,
    selectedDocumentTags,
    selectedSearch,
  }: IUseGetMethodologyDocuments) => {
    const selectedProcessQuery = makeSelectedProcessQuery(selectedProcess ? selectedProcess : selectedProcessStore);

    const localPaginationQuery = paginationQuery
      ? paginationQuery
      : makePaginationQuery({
          size: documentsMeta?.per_page,
          number: documentsMeta?.current_page,
        });
    const selectedDocumentStatusesQuery = makeSelectedDocumentStatusesQuery(
      selectedDocumentStatuses ? selectedDocumentStatuses : selectedDocumentStatusesStore
    );
    const selectedDocumentTypesQuery = makeSelectedDocumentTypesQuery(
      selectedDocumentTypes ? selectedDocumentTypes : selectedDocumentTypesStore
    );

    const selectedDocumentTagsQuery = makeSelectedDocumentTagsQuery(
      selectedDocumentTags ? selectedDocumentTags : selectedDocumentTagsStore
    );
    console.log('current:' + selectedSearch, 'from store: ' + selectedSearchStore);
    const selectedSearhQuery = makeSelectedSearchQuery(selectedSearch ? selectedSearch : selectedSearchStore);

    const queryString = combineQuery(
      selectedProcessQuery,
      selectedDocumentStatusesQuery,
      selectedDocumentTypesQuery,
      selectedDocumentTagsQuery,
      selectedSearhQuery,
      localPaginationQuery
    );

    dispatch(getDocumentsThunk({ queryString: queryString }));
  };
};
