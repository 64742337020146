import React from 'react';

import digitalIco from '../../../img/reportsPage/digital.svg';
import industrialIco from '../../../img/reportsPage/industrial.svg';
import schemeImg from '../../../img/reportsPage/scheme.svg';
import classes from './ReportsAbout.module.css';

const ReportsAbout = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.title}>{'Оценка состоятельности в области \n управления данными DCAM 2024'}</div>
        <div className={classes.scheme}>
          <div className={classes.leftSide}>
            <div>
              <div className={classes.icon}>
                <img className={classes.img} src={digitalIco} alt="цифровая компания" />
                <div className={classes.iconName}>Цифровая компания</div>
                <div className={classes.iconDesc}>(датацентричная)</div>
              </div>
              <div className={classes.progress}>
                <div className={classes.level}>Уровень 6</div>
                <div className={classes.levelName}>Развитый</div>
                <div className={classes.rect}></div>
                <div className={classes.level}>Уровень 5</div>
                <div className={classes.levelName}>Зрелый</div>
                <div className={classes.rect} style={{ background: '#2FB4E9' }}></div>
                <div className={classes.level}>Уровень 4</div>
                <div className={classes.levelName}>Функциональный</div>
                <div className={classes.rect} style={{ background: '#0970BB' }}></div>
              </div>
            </div>
            <div>
              <div className={classes.icon}>
                <img className={classes.img} src={industrialIco} alt="индустриальная компания" />
                <div className={classes.iconName}>Индустриальная компания</div>
              </div>
              <div className={classes.progress}>
                <div className={classes.level}>Уровень 3</div>
                <div className={classes.levelName}>стратегический</div>
                <div className={classes.rect} style={{ background: '#4997DE' }}></div>
                <div className={classes.level}>Уровень 2</div>
                <div className={classes.levelName}>концептуальный</div>
                <div className={classes.rect} style={{ background: '#7FB6E8' }}></div>
                <div className={classes.level}>Уровень 1</div>
                <div className={classes.levelName}>неопределенный</div>
                <div className={classes.rect} style={{ background: '#A9D6FF' }}></div>
              </div>
            </div>
          </div>
          <div className={classes.centerBlock}></div>
          <div className={classes.center} style={{ background: `url(${schemeImg})` }}>
            <div className={classes.centerLevel} style={{ bottom: '-20px' }}>
              <div className={classes.centerTitle}>1 уровень</div>
            </div>
            <div className={classes.centerLevel} style={{ left: '101px', top: '313px' }}>
              <div className={classes.centerTitle}>2 уровень</div>
            </div>
            <div className={classes.centerLevel} style={{ left: '204px', top: '246px' }}>
              <div className={classes.centerTitle}>3 уровень — Стратегический</div>
              <div className={classes.centerDesc}>
                Практики управления данными связаны со стратегическим планом и целями организации
              </div>
            </div>
            <div className={classes.centerLevel} style={{ left: '292px', top: '140px' }}>
              <div className={classes.centerTitle}>4 уровень — Функциональный</div>
              <div className={classes.centerDesc}>
                Бизнес-пользователи играют активную роль. Практики управления данными связаны с KPI организации
              </div>
            </div>
            <div className={classes.centerLevel} style={{ left: '350px', top: '65px' }}>
              <div className={classes.centerTitle}>5 уровень</div>
            </div>
            <div className={classes.centerLevel} style={{ right: '0px', top: '7px' }}>
              <div className={classes.centerTitle}>6 уровень</div>
            </div>
          </div>
          <div className={classes.rightSide}>
            <strong>ИТОГИ 2024 ГОДА:</strong>
            <ul className={classes.rightUl}>
              <li>
                ГПН достигла оценки 4.05, что соответствует четвёртому (функциональному) уровню состоятельности DCAM
              </li>
              <li>Уровень состоятельности превышает общую оценку нефинансового (3.28) и финансового (3.63) сектора</li>
              <li>
                В глобальном отчёте 2023 года оценка нефинансовых компаний объединена и приводится без разбивки по
                секторам
              </li>
              <li>
                Для подтверждения четвёртого уровня необходимо провести сбор и верификацию документальных подтверждений
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ReportsAbout;
