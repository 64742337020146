import React from 'react';

import classes from './AdminEducationAnnounceTableCoverImg.module.css';

interface IAdminBlogTableCoverImgProps {
  imgUrl?: string | null;
}

const AdminEducationAnnounceTableCoverImg: React.FC<IAdminBlogTableCoverImgProps> = ({ imgUrl }) => {
  if (!imgUrl) {
    return null;
  }
  return (
    <div className={classes.container}>
      <img src={imgUrl} alt="coverImg" className={classes.img} />;
    </div>
  );
};

export default AdminEducationAnnounceTableCoverImg;
