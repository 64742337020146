import { serverApi } from '../api/serverApi';
import { RootState } from './store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BlogItemsType, ViewSelectorItemType } from '../types/types';
import { getAdminBlogItemsThunk } from './adminBlogSlice';

export const ADMIN_EDUCATION_PAGE_VIEW_SELECTOR_ITEMS: ViewSelectorItemType[] = [
  {
    name: 'Анонсы',
    slug: 'announce',
  },
  {
    name: 'Информация об обучении',
    slug: 'education',
  },
  {
    name: 'Видеоматериалы',
    slug: 'video',
  },
  {
    name: 'Лучшие практики',
    slug: 'practice',
  },
];

type EducationAdminSliceType = {
  viewSelectorActiveItem: ViewSelectorItemType | null;
  activeSortingId: number;
  searchValue: string | undefined;
  announceItems: any | undefined;
  inArchive: boolean;
  isLoading: boolean;
  filter: string;
  staticData: any;
};

const initialState: EducationAdminSliceType = {
  viewSelectorActiveItem: ADMIN_EDUCATION_PAGE_VIEW_SELECTOR_ITEMS[0],
  searchValue: undefined,
  activeSortingId: 1,
  inArchive: false,
  isLoading: false,
  announceItems: undefined,
  filter: '',
  staticData: null,
};

export const getAdminEducationAnnounceItemsThunk = createAsyncThunk<any, string | undefined, { rejectValue: string }>(
  'adminEducationPageSlice/getAdminEducationAnnounceItemsThunk',
  async (params, { rejectWithValue }) => {
    try {
      return await serverApi.getEducationAnnounceItems(params);
    } catch (e) {
      return rejectWithValue('Ошибка получения элементов блога');
    }
  }
);

export const adminEducationSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setAdminEducationActiveItem: (state, action) => {
      state.viewSelectorActiveItem = action.payload;
    },
    setAdminEducationSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setAdminEducationInArchive: (state, action) => {
      state.inArchive = action.payload;
    },
    setAdminEducationActiveSortingId: (state, action) => {
      state.activeSortingId = action.payload;
    },
    setAdminEducationFilter: (state, action) => {
      state.activeSortingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminEducationAnnounceItemsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.announceItems = action.payload;
      })
      .addCase(getAdminEducationAnnounceItemsThunk.pending, (state) => {
        state.announceItems = undefined;
        state.isLoading = true;
      });
  },
});

export const {
  setAdminEducationInArchive,
  setAdminEducationSearchValue,
  setAdminEducationActiveItem,
  setAdminEducationActiveSortingId,
  setAdminEducationFilter,
} = adminEducationSlice.actions;

export const selectorEducationViewSelectorActiveItem = (state: RootState) =>
  state.adminEducation.viewSelectorActiveItem;
export const selectorEducationViewSelectorSearchValue = (state: RootState) => state.adminEducation.searchValue;
export const selectorEducationViewSelectorInArchive = (state: RootState) => state.adminEducation.inArchive;
export const selectorEducationViewSelectorActiveSortingId = (state: RootState) => state.adminEducation.activeSortingId;
export const selectorAdminEducationIsLoading = (state: RootState) => state.adminEducation.isLoading;
export const selectorAdminEducationFilter = (state: RootState) => state.adminEducation.filter;
export const selectorViewSelectorEducationAnnounceItems = (state: RootState) => state.adminEducation.announceItems;

export default adminEducationSlice.reducer;
