import React from 'react';

import { useAppSelector } from '../../../../hooks/hooks';
import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import classes from './AdminEducationPageContent.module.css';
import AdminEducationTable from './AdminEducationAnnounceTable/AdminEducationAnnounceTable';
import { Loader } from '@consta/uikit/Loader';
import {
  selectorAdminEducationIsLoading,
  selectorEducationViewSelectorActiveItem,
} from '../../../../store/adminEducationSlice';
import AdminEducationDocumentsTable from './AdminEducationAnnounceTable/AdminEducationAnnounceTable';
import AdminEducationAnnounceTable from './AdminEducationAnnounceTable/AdminEducationAnnounceTable';

interface IAdminBlogPageContentProps {}

const AdminEducationPageContent: React.FC<IAdminBlogPageContentProps> = () => {
  const isLoadingItems = useAppSelector(selectorAdminEducationIsLoading);
  const view = useAppSelector(selectorEducationViewSelectorActiveItem);

  return (
    <div className={classes.container}>
      <Delimiter />
      {view?.slug == 'announce' && (
        <div className={classes.content}>{isLoadingItems ? <Loader /> : <AdminEducationAnnounceTable />}</div>
      )}
      {view?.slug == 'documents' && (
        <div className={classes.content}>{isLoadingItems ? <Loader /> : <AdminEducationDocumentsTable />}</div>
      )}

      <Delimiter />
    </div>
  );
};

export default AdminEducationPageContent;
