import React, { useRef, useState } from 'react';

import { reportsApi } from '../../../../api/reportsApi';
import { ReportsDictionaryType, ReportsItemType } from '../../../../types/types';
import ReportsForm from '../../ReportsForm/ReportsForm';
import ReportsModalDelete from '../../ReportsModalDelete/ReportsModalDelete';
import classes from './ReportsItem.module.css';
import { Button } from '@consta/uikit/Button';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconComponent } from '@consta/uikit/Icon';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconKebab } from '@consta/uikit/IconKebab';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Modal } from '@consta/uikit/Modal';
import { Tag } from '@consta/uikit/Tag';
import parse from 'html-react-parser';
import { pushSnackBarItemReports } from '../../../../store/appSlice';
import { useAppDispatch } from '../../../../hooks/hooks';
import { IconClose } from '@consta/uikit/IconClose';

type PropsType = {
  el: ReportsItemType;
  dictionaries: ReportsDictionaryType;
  refetch: () => void;
};

type ContextMenuItem = {
  label: string;
  icon: IconComponent;
  status?: string;
  value: string;
};

const ReportsItem: React.FC<PropsType> = ({ el, dictionaries, refetch }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: 'Редактировать',
      value: 'EDIT',
      icon: IconEdit,
      status: 'link',
    },
    {
      label: 'Удалить',
      value: 'DELETE',
      icon: IconTrash,
      status: 'alert',
    },
  ];
  const onItemClick = (item: ContextMenuItem) => {
    item.value === 'DELETE' ? setIsOpenDeleteModal(true) : setIsOpenEditModal(true);
    return () => setIsOpenContextMenu(false);
  };
  const renderLeftSideItem = (item: any) => {
    const Icon = item.icon;
    return <Icon size="s" view={item.status} />;
  };
  const deleteReport = async (reportId: string | undefined) => {
    await reportsApi.deleteReport(reportId);
    setIsOpenDeleteModal(false);
    dispatch(
      pushSnackBarItemReports({
        key: Math.random(),
        message: `Отчет удален`,
        status: 'success',
        autoClose: 5,
      })
    );
    refetch();
  };

  return (
    <div className={classes.item}>
      <div className={classes.header}>
        <div className={classes.blocks}>
          {el.blocks && el.blocks.map((block, ind) => <Tag mode="info" label={block.name} key={ind} size="s" />)}
        </div>
        <div>
          {el.system && (
            <>
              <img src={el.system.icon} alt={''} />
              {el.system.name}
            </>
          )}
        </div>
      </div>
      <div className={classes.title}>{el.name}</div>
      <div className={classes.buttons}>
        {el.hasAccess && (
          <Button size="s" form="round" view="primary" label="Перейти" onClick={() => window.open(el.link, '_blank')} />
        )}
        {!el.hasAccess && (
          <Button
            size="s"
            form="round"
            view="ghost"
            label="Нет доступа"
            iconRight={IconQuestion}
            onClick={() => setIsOpen(true)}
          />
        )}
        <ReportsModalDelete
          isModalOpen={isOpenDeleteModal}
          setIsModalOpen={setIsOpenDeleteModal}
          modalDeleteTitle={'Вы действительно хотите удалить отчет?'}
          onConfirmDelete={() => deleteReport(el.uuid)}
        />
        <Modal
          isOpen={isOpen}
          className={classes.reportsModal}
          hasOverlay
          /*  onClickOutside={() => setIsOpen(false)}*/
          onEsc={() => setIsOpen(false)}
        >
          <Button
            size={'m'}
            onlyIcon
            view={'clear'}
            className={classes.closeButton}
            iconRight={IconClose}
            onClick={() => setIsOpen(false)}
          />
          <div className={classes.accessDesc}>Инструкция по получению доступа</div>
          {parse(el.manual ? el.manual : '')}
        </Modal>
        {el.can_edit && (
          <>
            <Button
              size="s"
              form="round"
              view="ghost"
              onlyIcon
              iconRight={IconKebab}
              className={classes.edit}
              ref={ref}
              onClick={() => setIsOpenContextMenu((prev) => !prev)}
            />
            <ContextMenu
              items={contextMenuItems}
              isOpen={isOpenContextMenu}
              getItemLeftSide={renderLeftSideItem}
              anchorRef={ref}
              getItemOnClick={(item) => onItemClick(item)}
              direction="downStartRight"
              onClickOutside={() => setIsOpenContextMenu(false)}
            />
          </>
        )}
      </div>
      <Modal
        isOpen={isOpenEditModal}
        className={classes.reportsModal}
        hasOverlay
        /*onClickOutside={() => setIsOpenEditModal(false)}*/
        onEsc={() => setIsOpenEditModal(false)}
      >
        <ReportsForm
          onCancel={() => setIsOpenEditModal(false)}
          dictionaries={dictionaries}
          refetch={() => refetch()}
          item={el}
        />
      </Modal>
    </div>
  );
};

export default ReportsItem;
