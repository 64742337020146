import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import {
  getCommandDivisionThunk,
  getCommandTeamThunk,
  postCommandGroupsThunk,
  pushSnackBarItemTeam,
  selectorDivisions,
} from '../../../../store/commandSlice';
import classes from './TeamGroupsFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TextField } from '@consta/uikit/TextField';
import { IconAdd } from '@consta/uikit/IconAdd';
import { pushSnackBarItemReports } from '../../../../store/appSlice';

type Item = {
  label: string;
  id?: string;
  ord: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const TeamGroupsFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const divisions: Item[] = useAppSelector(selectorDivisions);
  const [items, setItems] = useState<Item[]>(divisions);

  const setDivionsGroup = useCallback(
    (value: string | null, key: number) => {
      if (value) {
        const tmp = [...(items as Item[])];

        tmp[key] = {
          ord: tmp[key].ord,
          id: tmp[key].id,
          label: value,
        };
        setItems(tmp);
      }
    },
    [items]
  );

  const deleteGroup = useCallback(
    (key: number) => {
      const tmp = [...(items as Item[])].filter((el, idx) => key !== idx);
      if (tmp) {
        setItems(tmp);
      }
    },
    [items]
  );

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const tmp = reorder(items, result.source.index, result.destination.index);
    setItems(tmp as Item[]);
  };

  const postGroups = useCallback(() => {
    const tmp: any[] = [];
    items
      .filter((el) => el.label)
      .forEach((el, key) => {
        if (el.id) {
          tmp.push({
            _uuid: el.id,
            name: el.label,
            ord: key,
          });
        } else {
          tmp.push({
            _uuid: el.id,
            name: el.label,
            ord: key,
          });
        }
      });
    dispatch(postCommandGroupsThunk(tmp)).finally(() => {
      closeSideBar();
      dispatch(
        pushSnackBarItemTeam({
          key: Math.random(),
          message: `Группы обновлены`,
          status: 'success',
          autoClose: 5,
        })
      );
      dispatch(getCommandDivisionThunk()).then();
      dispatch(getCommandTeamThunk()).then();
    });
  }, [items, closeSideBar, dispatch]);

  const addGroup = useCallback(() => {
    const tmp = [...(items as Item[])];
    if (tmp) {
      tmp.push({
        label: '',
        ord: 999,
      });
      setItems(tmp);
    }
  }, [items]);

  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Информация о подразделениях</div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items &&
                items.length > 0 &&
                items.map((division, key) => (
                  <Draggable key={key} draggableId={key.toString()} index={key}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div className={classes.sidebarItem} key={key}>
                          <div className={classes.itemTitle}>
                            <IconDraggable size={'s'} /> Подразделение №{key + 1}{' '}
                            <IconTrash size={'s'} className={classes.IconTrash} onClick={() => deleteGroup(key)} />
                          </div>
                          <TextField
                            onChange={(value) => setDivionsGroup(value.value, key)}
                            value={division.label}
                            type="textarea"
                            cols={200}
                            rows={2}
                            placeholder="Введите название практики"
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        size={'s'}
        view={'ghost'}
        label={'Добавить еще'}
        onClick={addGroup}
        className={classes.buttonAdd}
        iconLeft={IconAdd}
      />

      <div className={classes.bottomButtons}>
        <div></div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button size="m" view="primary" form={'default'} label={'Сохранить'} onClick={postGroups} />
        </div>
      </div>
    </div>
  );
};

export default TeamGroupsFormComponent;
