import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { setAdminMethodologyInArchive } from '../../../../../store/adminMethodologySlice';
import {
  ADMIN_EDUCATION_PAGE_VIEW_SELECTOR_ITEMS,
  selectorEducationViewSelectorActiveItem,
  selectorEducationViewSelectorInArchive,
  setAdminEducationActiveItem,
} from '../../../../../store/adminEducationSlice';
import { ViewSelectorItemType } from '../../../../../types/types';
import classes from './AdminEducationPageViewSelector.module.css';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Switch } from '@consta/uikit/Switch';
import { Text } from '@consta/uikit/Text';
import { useGetProcesses } from '../../../../../hooks/useGetProcesses';
import { useGetDocuments } from '../../../../../hooks/useGetDocuments';
import { useGetEducationAnnounceItems } from '../../../../../hooks/useGetEducationAnnounceItems';

interface IAdminBlogPageViewSelectorProps {}

const AdminEducationyPageViewSelector: React.FC<IAdminBlogPageViewSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector(selectorEducationViewSelectorActiveItem);
  const [inArchive, setInArchive] = useState<boolean>(false);
  const inArchiveStore = useAppSelector(selectorEducationViewSelectorInArchive);

  const onChangeViewSelector = ({ value }: { value: ViewSelectorItemType }) => {
    dispatch(setAdminEducationActiveItem(value));
  };

  const getAnnounces = useGetEducationAnnounceItems();
  /*const getDocuments = useGetDocuments();*/

  const handleChange = useCallback(
    (checked: boolean) => {
      setInArchive(checked);
      dispatch(setAdminMethodologyInArchive(checked));

      if (view?.slug === 'announce') {
        getAnnounces({ inArchive: checked });
      }
      /*if (view?.slug === 'documents') {
        getDocuments({ inArchive: checked });
      }*/
    },
    [view]
  );

  return (
    <div className={classes.container}>
      <div>
        <ChoiceGroup
          size={'s'}
          value={view}
          onChange={onChangeViewSelector}
          items={ADMIN_EDUCATION_PAGE_VIEW_SELECTOR_ITEMS}
          getItemLabel={(item: ViewSelectorItemType) => item.name}
          multiple={false}
          name={'adminMethodologyPageViewSelector'}
        />

        <div className={classes.switch}>
          {<Text className={`${classes.title} ${inArchiveStore ? classes.active : ''}`}>{'В архиве'}</Text>}
          <Switch view="ghost" checked={inArchive} onChange={({ checked }) => handleChange(checked)} />
        </div>
      </div>
    </div>
  );
};

export default AdminEducationyPageViewSelector;
