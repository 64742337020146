import React, { useRef, useState } from 'react';
import ColumnSortingAndFilter from '../column-sorting-and-filter/ColumnSortingAndFilter';
import s from './SortingAndFilterColumnHeader.module.css';
import { Popover } from '@consta/uikit/Popover';

interface IStringColumnHeader {
  value: string;
  sortingDirection?: boolean | string;
  toggleColumnSorting?: () => void;
  filterComponent?: React.FC;
  isFiltered?: boolean;
  onChangeIsFilterVisible?: (isVisible: boolean) => void;
  isFilterOpen?: boolean;
  openFilterPopup?: () => void;
  closeFilterPopup?: () => void;
}

const SortingAndFilterColumnHeader = ({
  value,
  sortingDirection,
  toggleColumnSorting,
  filterComponent,
  isFiltered,
  onChangeIsFilterVisible,
  isFilterOpen,
  openFilterPopup,
  closeFilterPopup,
}: IStringColumnHeader): JSX.Element => {
  const isExternalManagement = !!openFilterPopup && !!closeFilterPopup;
  const columnHeader = useRef<HTMLDivElement>(null);
  const columnHeaderWrapper = useRef<HTMLDivElement>(null);
  const [isHeaderHover, setIsHeaderHover] = useState(false);
  const thHeight = columnHeader.current?.parentElement?.parentElement?.clientHeight;
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsHeaderHover(true);
  };

  const handleMouseLeave = () => {
    setIsHeaderHover(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    event.stopImmediatePropagation();
    if (isExternalManagement) {
      closeFilterPopup();
    } else {
      setIsFilterPopoverVisible(false);
    }
    if (onChangeIsFilterVisible) {
      onChangeIsFilterVisible(false);
    }
  };

  return (
    <div
      className={s.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ minHeight: thHeight }}
      ref={columnHeaderWrapper}
    >
      <div className={s.columnHeader} ref={columnHeader}>
        {value || ''}
      </div>
      <ColumnSortingAndFilter
        sortingDirection={sortingDirection}
        toggleColumnSorting={toggleColumnSorting}
        isHeaderHover={isHeaderHover}
        filterComponent={filterComponent}
        isFiltered={isFiltered}
        isFilterPopoverVisible={isFilterPopoverVisible}
        setIsFilterPopoverVisible={setIsFilterPopoverVisible}
        onChangeIsFilterVisible={onChangeIsFilterVisible}
        isFilterOpen={isFilterOpen}
        openFilterPopup={openFilterPopup}
        closeFilterPopup={closeFilterPopup}
      />
      {isExternalManagement
        ? isFilterOpen && (
            <Popover
              anchorRef={columnHeaderWrapper}
              offset={2}
              direction="downStartLeft"
              possibleDirections={['downStartRight']}
              onClickOutside={(event) => handleClickOutside(event)}
              placeholder={undefined}
            >
              {filterComponent}
            </Popover>
          )
        : isFilterPopoverVisible && (
            <Popover
              anchorRef={columnHeaderWrapper}
              offset={2}
              direction="downStartLeft"
              possibleDirections={['downStartRight']}
              onClickOutside={(event) => handleClickOutside(event)}
              placeholder={undefined}
            >
              {filterComponent}
            </Popover>
          )}
    </div>
  );
};

export default SortingAndFilterColumnHeader;
