import React from 'react';

import Delimiter from '../../../../shared-front/Delimiter/Delimiter';
import AdminBlogPageActionRow from './AdminBlogPageActionRow/AdminBlogPageActionRow';
import classes from './AdminEducationPageHeader.module.css';
import { Text } from '@consta/uikit/Text';
import AdminEducationyPageViewSelector from './AdminEducationPageViewSelector/AdminEducationyPageViewSelector';
interface IAdminBlogPageHeaderProps {}

const AdminEducationPageHeader: React.FC<IAdminBlogPageHeaderProps> = () => {
  return (
    <div className={classes.container}>
      <Text className={classes.title}>{'Администрирование обучения'}</Text>
      <AdminEducationyPageViewSelector />
      <Delimiter />
      <AdminBlogPageActionRow />
    </div>
  );
};

export default AdminEducationPageHeader;
