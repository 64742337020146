import { adminApi } from './adminApi';
import { adminBlogApi } from './adminBlogApi';
import { appApi } from './appApi';
import { blogApi } from './blogApi';
import { catalogsSearchApi } from './catalogsSearchApi';
import { dictionaryApi } from './dictionaryApi';
import { learningApi } from './learningApi';
import { methodologyApi } from './methodologyApi';
import { reportsApi } from './reportsApi';
import { teamApi } from './teamApi';
import { contactsApi } from './contactsApi';
import { adminMethodologyApi } from './adminMethodologyAdminApi';
import { adminEducationApi } from './adminEducationApi';

export const serverApi = {
  ...methodologyApi,
  ...learningApi,
  ...catalogsSearchApi,
  ...blogApi,
  ...appApi,
  ...adminApi,
  ...reportsApi,
  ...dictionaryApi,
  ...teamApi,
  ...adminBlogApi,
  ...contactsApi,
  ...adminMethodologyApi,
  ...adminEducationApi,
};
