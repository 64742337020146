import React from 'react';

import { DomainOrProgramType } from '../../../../../types/types';
import EmbeddingCard from './EmbeddingCard/EmbeddingCard';
import classes from './GroupSection.module.css';

type PropsType = {
  domainGroupSection: DomainOrProgramType[];
  isLastSection: boolean;
  domainName: string;
  domainUuid: string;
  type: string;
  openSideBar: () => void;
  groupDomainUUid: string;
};

const GroupSection: React.FC<PropsType> = ({
  domainGroupSection,
  isLastSection,
  domainName,
  domainUuid,
  type,
  openSideBar,
  groupDomainUUid,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {[...domainGroupSection]
          .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))
          .map((domain, ind) => {
            return (
              <EmbeddingCard
                key={ind}
                domainOrProgram={domain}
                domainName={domainName}
                domainUuid={domainUuid}
                openSideBar={openSideBar}
                groupDomainUUid={groupDomainUUid}
                type={type}
              />
            );
          })}
      </div>
      {!isLastSection && <div className={classes.delimiter} />}
    </div>
  );
};

export default GroupSection;
