import React from 'react';

import classes from './TableColumnHeader.module.css';
import classNames from 'classnames';
import SortingAndFilterColumnHeader from '../table-column-header/SortingAndFilterColumnHeader';
import { Column } from '@tanstack/react-table';

interface ICommonHeaderProps {
  title: string;
  minColumnWidth?: string;
  maxColumnWidth?: string;
  cellHeight?: string;
  children?: React.ReactNode;
  column?: Column<any>;
  sortingDirection?: boolean | string;
  toggleColumnSorting?: () => void;
  filterComponent?: React.FC;
  isFiltered?: boolean;
  onChangeIsFilterVisible?: (isVisible: boolean) => void;
  isFilterOpen?: boolean;
  openFilterPopup?: () => void;
  closeFilterPopup?: () => void;
}

const TableColumnHeader: React.FC<ICommonHeaderProps> = ({
  title,
  minColumnWidth,
  maxColumnWidth,
  cellHeight,
  children,
  sortingDirection,
  toggleColumnSorting,
  filterComponent,
  isFiltered,
  onChangeIsFilterVisible,
  isFilterOpen,
  openFilterPopup,
  closeFilterPopup,
}) => {
  return (
    <div
      className={classNames(classes.container, { [classes.withChildren]: children })}
      style={{ minWidth: minColumnWidth, maxWidth: maxColumnWidth, height: cellHeight }}
    >
      {/*<div>{title}</div>*/}
      {/*<div className={classes.children}>{children}</div>*/}
      <SortingAndFilterColumnHeader
        value={title}
        sortingDirection={sortingDirection}
        toggleColumnSorting={toggleColumnSorting}
        filterComponent={filterComponent}
        isFiltered={isFiltered}
        onChangeIsFilterVisible={onChangeIsFilterVisible}
        isFilterOpen={isFilterOpen}
        openFilterPopup={openFilterPopup}
        closeFilterPopup={closeFilterPopup}
      />
    </div>
  );
};

export default TableColumnHeader;
