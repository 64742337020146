import React from 'react';

import reportIco from '../../../img/mainPage/reportIco.svg';
import serviceIco from '../../../img/mainPage/serviceIco.svg';
import { openURLinNewTab } from '../../../utils/openURLinNewTab';
import classes from './MaintainingBlock.module.css';
import { Select } from '@consta/uikit/Select';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { useNavigate } from 'react-router-dom';

type Item = {
  label: string;
  id: number;
  url: string;
  disabled?: boolean;
};

const items: Item[] = [
  {
    id: 1,
    label: 'БРД',
    url: 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=16948120',
  },
  {
    id: 2,
    label: 'БЛПС',
    disabled: true,
    url: 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=178098834',
  },
  /*{
    id: 3,
    label: 'ДРП',
    url: 'https://confluence.gazprom-neft.local/display/ASCPublic/Analytics+Solution+Center+Public+Home',
  },*/
];

const MaintainingBlock: React.FC = () => {
  const navigate = useNavigate();
  const onChange = ({ value }: { value: Item | null }) => {
    openURLinNewTab(value?.url);
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Сопровождаем проекты и сервисы`}</Text>
      <Text
        className={classes.description}
      >{`Участвуем в проектной деятельности компании,\nпредоставляем сервисы и оказываем постоянный мониторинг работы функции`}</Text>
      <div className={classes.cardsContainer}>
        <div className={classes.card}>
          <div className={classes.titleRow}>
            <img src={serviceIco} alt="serviceIco" />
            <Text className={classes.cardTitle}>{`Проекты и сервисы`}</Text>
          </div>
          <Text
            className={classes.cardDescription}
          >{`Рассказываем о проектах, в которых участвует функция, а также\nоб оказываемых сервисах`}</Text>
          <Select
            placeholder="Перейти в Confluence"
            form="round"
            items={items}
            onChange={onChange}
            size={'s'}
            className={classes.mySelect}
          />
        </div>
        <div className={classes.card}>
          <div className={classes.titleRow}>
            <img src={reportIco} alt="reportIco" />
            <Text className={classes.cardTitle}>{`Отчеты`}</Text>
          </div>
          <Text className={classes.cardDescription}>{`Показываем статистику по ключевым показателями функции`}</Text>
          <div>
            <Button label={'Перейти к отчетам'} view={'primary'} form={'round'} onClick={() => navigate('/reports')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintainingBlock;
