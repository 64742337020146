import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';
import { IconUnsort } from '@consta/uikit/IconUnsort';

import { IconFunnel } from '@consta/uikit/IconFunnel';
import React, { Dispatch, SetStateAction, useState } from 'react';
import s from './ColumnSortingAndFilter.module.css';

interface IColumnSortingAndFilter {
  sortingDirection?: boolean | string;
  toggleColumnSorting?: () => void;
  isHeaderHover?: boolean;
  isFilterPopoverVisible: boolean;
  setIsFilterPopoverVisible: Dispatch<SetStateAction<boolean>>;
  filterComponent?: React.FC;
  isFiltered?: boolean;
  onChangeIsFilterVisible?: (isVisible: boolean) => void;
  isFilterOpen?: boolean;
  openFilterPopup?: () => void;
  closeFilterPopup?: () => void;
}

const ColumnSortingAndFilter = ({
  sortingDirection,
  filterComponent,
  toggleColumnSorting,
  isHeaderHover,
  isFilterPopoverVisible,
  setIsFilterPopoverVisible,
  isFiltered,
  onChangeIsFilterVisible,
  isFilterOpen,
  openFilterPopup,
  closeFilterPopup,
}: IColumnSortingAndFilter) => {
  const isExternalManagement = !!openFilterPopup && !!closeFilterPopup;
  const [isSortingIconHover, setIsSortingIconHover] = useState(false);
  const [isFilterIconHover, setIsFilterIconHover] = useState(false);

  const handleSortingIconMouseEnter = () => {
    setIsSortingIconHover(true);
  };

  const handleSortingIconMouseLeave = () => {
    setIsSortingIconHover(false);
  };

  const handleFilterIconMouseEnter = () => {
    setIsFilterIconHover(true);
  };

  const handleFilterIconMouseLeave = () => {
    setIsFilterIconHover(false);
  };

  const handleClickOnFilterIcon = () => {
    if (isExternalManagement) {
      if (isFilterOpen) {
        closeFilterPopup();
        if (onChangeIsFilterVisible) {
          onChangeIsFilterVisible(false);
        }
      } else {
        openFilterPopup();
        if (onChangeIsFilterVisible) {
          onChangeIsFilterVisible(true);
        }
      }
    } else {
      setIsFilterPopoverVisible((prev) => {
        if (onChangeIsFilterVisible) {
          onChangeIsFilterVisible(!prev);
        }
        return !prev;
      });
    }
  };

  let sortingIcon;
  let filterIcon;

  if (isSortingIconHover || !sortingDirection) {
    sortingIcon = <IconUnsort size="s" view="secondary" />;
  } else {
    if (sortingDirection) {
      if (sortingDirection === 'desc') {
        sortingIcon = <IconSortDown size="s" view="link" />;
      } else {
        sortingIcon = <IconSortUp size="s" view="link" />;
      }
    }
  }

  if (isFilterIconHover || !isFiltered) {
    filterIcon = <IconFunnel size="s" view="secondary" />;
  } else {
    if (isFiltered) {
      filterIcon = <IconFunnel size="s" view="link" />;
    }
  }

  return (
    <div className={s.wrapper}>
      {sortingDirection !== undefined && (
        <div
          className={s.icon}
          onClick={toggleColumnSorting}
          onMouseEnter={handleSortingIconMouseEnter}
          onMouseLeave={handleSortingIconMouseLeave}
          style={{ visibility: sortingDirection || isHeaderHover ? undefined : 'hidden' }}
        >
          {sortingIcon}
        </div>
      )}
      {filterComponent && (
        <div
          className={s.icon}
          onMouseEnter={handleFilterIconMouseEnter}
          onMouseLeave={handleFilterIconMouseLeave}
          onClick={handleClickOnFilterIcon}
          style={{
            visibility: isExternalManagement
              ? isFilterOpen || isFiltered || isHeaderHover
                ? undefined
                : 'hidden'
              : isFilterPopoverVisible || isFiltered || isHeaderHover
              ? undefined
              : 'hidden',
          }}
        >
          {filterIcon}
        </div>
      )}
    </div>
  );
};

export default ColumnSortingAndFilter;
