import React, { useEffect } from 'react';

import ModalError from '../../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { selectorErrorAdminBlogPage } from '../../../store/adminBlogSlice';
import classes from './AdminEducationPage.module.css';
import AdminEducationPageContent from './AdminEducationPageContent/AdminEducationPageContent';
import AdminEducationPageHeader from './AdminEducationPageHeader/AdminEducationPageHeader';
import AdminBlogPagePaginationRow from './AdminEducationPagePaginationRow/AdminBlogPagePaginationRow';
import {
  getAdminMethodologyDocumentsThunk,
  getAdminMethodologyProcessesThunk,
  selectorMethodologyViewSelectorActiveItem,
} from '../../../store/adminMethodologySlice';
import {
  getAdminEducationAnnounceItemsThunk,
  selectorEducationViewSelectorActiveItem,
} from '../../../store/adminEducationSlice';

interface IAdminBlogPageProps {}

const AdminEducationPage: React.FC<IAdminBlogPageProps> = () => {
  const dispatch = useAppDispatch();
  const errorAdminBlogPage = useAppSelector(selectorErrorAdminBlogPage);
  const view = useAppSelector(selectorEducationViewSelectorActiveItem);

  useEffect(() => {
    if (view?.slug === 'announce') {
      dispatch(getAdminEducationAnnounceItemsThunk(`?sort=-_create_ts&page[size]=25&page[number]=1&in_archive=false`));
    } else {
      dispatch(getAdminMethodologyDocumentsThunk(`?sort=-_create_ts&page[size]=25&page[number]=1`));
    }
  }, [dispatch, view]);

  return (
    <div className={classes.container}>
      <AdminEducationPageHeader />
      <AdminEducationPageContent />
      <AdminBlogPagePaginationRow />
      <ModalError errorMessage={errorAdminBlogPage} />
    </div>
  );
};

export default AdminEducationPage;
