import React from 'react';

import dataIco from '../../../../img/mainPage/dataIco.svg';
import policyIco from '../../../../img/mainPage/policyIco.svg';
import teachingIco from '../../../../img/mainPage/teachingIco.svg';
import { openURLinNewTab } from '../../../../utils/openURLinNewTab';
import classes from './ModalPlungeInData.module.css';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

interface IModalPlungeInDataProps {
  closeModal: () => void;
}

const ModalPlungeInData: React.FC<IModalPlungeInDataProps> = ({ closeModal }) => {
  const teachingClick = () => {
    openURLinNewTab('https://edu.gazprom-neft.local/knowledge_portal/nop/7114530767425592750');
  };

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <Text className={classes.title}>{`Инструкция по погружению в работу с данными`}</Text>
        <Button label="закрыть" view="clear" iconRight={IconClose} size={'s'} onlyIcon onClick={closeModal} />
      </div>
      <div className={classes.delimiter} />
      <div className={classes.cardsContainer}>
        <div className={classes.card}>
          <img src={policyIco} alt="policyIco" className={classes.ico} />
          <Text className={classes.cardTitle}>{`Шаг 1: Политика`}</Text>
          <Text
            className={classes.description}
          >{`Познакомьтесь с основополагающим документом Управления данными`}</Text>
          <div>
            <Button
              label="Смотреть документ"
              view="clear"
              iconRight={IconForward}
              size={'s'}
              className={classes.cardBtn}
              onClick={() =>
                openURLinNewTab(
                  'https://cnmd.gazprom-neft.local/documents/cb2e8b7b-5684-4b02-858e-823085ad7010/version/b905c803-e3d0-4964-9a53-dc93ce031865'
                )
              }
            />
          </div>
        </div>
        <div className={classes.card}>
          <img src={teachingIco} alt="teachingIco" className={classes.ico} />
          <Text className={classes.cardTitle}>{`Шаг 2: Обучение`}</Text>
          <Text className={classes.description}>{`Пройдите короткий курс по управлению метаданными`}</Text>
          <div>
            <Button
              label="Пройти обучение"
              view="clear"
              iconRight={IconForward}
              size={'s'}
              className={classes.cardBtn}
              onClick={teachingClick}
            />
          </div>
        </div>
        <div className={classes.card}>
          <img src={dataIco} alt="dataIco" className={classes.ico} />
          <Text className={classes.cardTitle}>{`Шаг 3: Процессы`}</Text>
          <Text className={classes.description}>{`Детально ознакомьтесь\nс процессами управления данными`}</Text>
          <div>
            <Button
              label="Изучить процессы"
              view="clear"
              iconRight={IconForward}
              size={'s'}
              className={classes.cardBtn}
              onClick={() => openURLinNewTab('https://cnmd.gazprom-neft.local/catalog')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPlungeInData;
