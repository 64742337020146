import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductInfoType } from '../../types/types';
import ProductPerformance from '../ProductPerformance/ProductPerformance';
import classes from './SectionProductHero.module.css';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconLink } from '@consta/uikit/IconLink';
import { IconOpenInNew } from '@consta/uikit/IconOpenInNew';

type PropsType = {
  productInfo: ProductInfoType;
  backgroundImage: string;
  parentName: string;
  parentLink: string;
};

const SectionProductHero: React.FC<PropsType> = ({ productInfo, backgroundImage, parentName, parentLink }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div
        className={classes.contentContainer}
        style={{ background: `url(${backgroundImage}) right bottom no-repeat`, backgroundSize: 'contain' }}
      >
        <div className={classes.breadcrumbs}>
          <div className={classes.breadcrumbsLink} onClick={() => navigate(parentLink)}>
            {parentName}
          </div>
          <IconArrowRight size="s" view="ghost" className={classes.breadcrumbsArrow} />
          <div className={classes.breadcrumbsCurrent}>{productInfo.name}</div>
        </div>
        <div className={classes.productTitle} style={{ whiteSpace: productInfo.slug === 'dict' ? 'nowrap' : '' }}>
          {productInfo.name}
        </div>

        <Text size="m" className={classes.subtitle} style={{ fontSize: productInfo.slug === 'dict' ? '18px' : '' }}>
          {productInfo.description}
        </Text>
        <ProductPerformance productInfo={productInfo} />
        {productInfo.slug === 'catalog' && (
          <>
            <Button
              size={'s'}
              form={'round'}
              view={'primary'}
              label={'Технические метаданные'}
              iconRight={IconOpenInNew}
              className={classes.heroButton}
            />
            <Button
              size={'s'}
              form={'round'}
              view={'primary'}
              label={'Каталог данных (EDM)'}
              iconRight={IconOpenInNew}
              className={classes.heroButton}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SectionProductHero;
