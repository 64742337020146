import { useEffect } from 'react';

const useGpnCounter = (counterId: number, fieldToCheck: string, valueToCheck: string) => {
  useEffect(() => {
    // Проверяем, что поле в process.env соответствует нужному значению
    if (process.env[fieldToCheck] !== valueToCheck) {
      return;
    }

    // Устанавливаем глобальные переменные
    (window as any).gpnSpaUrl = 'https://spa-back.gazprom-neft.local/events';
    (window as any).gpnCounterId = counterId;
    (window as any).apiVersion = '1.0';

    // Создаём элемент <script>
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://spa-back.gazprom-neft.local/static/counter.js';

    // Добавляем в <head>
    document.head.appendChild(script);

    return () => {
      // Очищаем скрипт при размонтировании компонента
      document.head.removeChild(script);
    };
  }, [counterId, fieldToCheck, valueToCheck]);
};

export default useGpnCounter;

// вызываем вот так в App useGpnCounter(950, "REACT_APP_ENABLE_GPN", "1"); // Загружается только если REACT_APP_ENABLE_GPN=1
