import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { useGetMethodologyDocuments } from '../../../../hooks/useGetMethodologyDocuments';
import Pagination from '../../../../shared-front/Pagination/Pagination';
import {
  selectorIsOpenLeftColumn,
  selectorMethodologyDocumentsMeta,
  selectorMethodologySelectedProcess,
  selectorViewType,
  setIsOpenLeftColumn,
} from '../../../../store/methodologySlice';
import { TypeViewEnum } from '../../../../types/types';
import AboutProcessView from './AboutProcessView/AboutProcessView';
import DocumentsCardsList from './DocumentsCardsList/DocumentsCardsList';
import DocumentsControl from './DocumentsControl/DocumentsControl';
import DocumentsTableContainer from './DocumentsTableContainer/DocumentsTableContainer';
import classes from './RightColum.module.css';
import { Button } from '@consta/uikit/Button';
import { IconDownload } from '@consta/uikit/IconDownload';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import { Text } from '@consta/uikit/Text';

const RightColum: React.FC = () => {
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(selectorViewType);
  const getMethodologyDocuments = useGetMethodologyDocuments();

  const isCardsView = viewType === TypeViewEnum.cards;
  const isTableView = viewType === TypeViewEnum.table;
  const isAboutView = viewType === TypeViewEnum.about;
  const selectedProcess = useAppSelector(selectorMethodologySelectedProcess);
  const documentsMeta = useAppSelector(selectorMethodologyDocumentsMeta);

  const isOpenLeftColumn = useAppSelector(selectorIsOpenLeftColumn);

  const openCloseClick = () => {
    if (isOpenLeftColumn) {
      dispatch(setIsOpenLeftColumn(false));
    } else {
      dispatch(setIsOpenLeftColumn(true));
    }
  };

  const onChangePagination = (paginationQuery: string) => {
    getMethodologyDocuments({ paginationQuery });
  };

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        {!isOpenLeftColumn && (
          <Button
            label={'open'}
            size={'xs'}
            view={'ghost'}
            iconRight={IconHamburger}
            onlyIcon
            onClick={openCloseClick}
            className={classes.btn}
          />
        )}
        <Text className={classes.title}>{selectedProcess ? selectedProcess.name : 'Все процессы'}</Text>
        {/*<div className={classes.btnContainer}>
          <Button size="s" label="Скачать файлы (Zip)" view="clear" iconLeft={IconDownload} />
        </div>*/}
      </div>
      {isCardsView && (
        <div className={classes.cardsView}>
          <DocumentsControl />
          <DocumentsCardsList />
          <div className={classes.paginationWrapper}>
            <Pagination
              currentPageSize={documentsMeta?.per_page}
              currentPage={documentsMeta?.current_page}
              lastPage={documentsMeta?.last_page}
              total={documentsMeta?.total}
              onChangePagination={onChangePagination}
            />
          </div>
        </div>
      )}
      {isTableView && (
        <div className={classes.tableView}>
          <DocumentsControl />
          <div className={classes.tableWrapper}>
            <DocumentsTableContainer />
          </div>
          <div className={classes.paginationWrapper}>
            <Pagination
              currentPageSize={documentsMeta?.per_page}
              currentPage={documentsMeta?.current_page}
              lastPage={documentsMeta?.last_page}
              total={documentsMeta?.total}
              onChangePagination={onChangePagination}
            />
          </div>
        </div>
      )}
      {isAboutView && selectedProcess && (
        <div className={classes.wrapperWithoutScroll}>
          <DocumentsControl />
          <AboutProcessView processUuid={selectedProcess.uuid} />
        </div>
      )}
    </div>
  );
};

export default RightColum;
