import React from 'react';

import classes from './AdminEducationAnnounceTableItemType.module.css';

interface IAdminBlogTableItemTypeProps {
  itemType?: string | null | undefined;
}

const AdminEducationAnnounceTableItemType: React.FC<IAdminBlogTableItemTypeProps> = ({ itemType }) => {
  if (!itemType) {
    return null;
  }
  return (
    <div className={classes.container}>
      {itemType === 'news'
        ? 'Новости'
        : itemType === 'announcement'
        ? 'Анонс'
        : itemType === 'best_practice'
        ? 'Кейс'
        : ''}
    </div>
  );
};

export default AdminEducationAnnounceTableItemType;
